import type { Menus } from '@/common/types'
import PageSeo from '@/components/core/PageSeo'
import RootLayout from '@/components/layout/RootLayout'
import BodyRenderer from '@/components/renderer/BodyRenderer'
import { REVALIDATE_TIME } from '@/env'
import type {
  ComponentSharedSeo,
  HomepageEntity,
  Maybe,
  PageEntity,
  PromoEntity,
} from '@/gql/generated/graphql'
import { Api } from '@/services/api'
import { nanoid } from 'nanoid'
import type { GetStaticProps } from 'next'
import React, { Fragment } from 'react'

type Props = {
  page?: PageEntity | HomepageEntity
  menus: Menus
  promos: PromoEntity[]
  isWhiteTheme?: boolean
  notSticky?: boolean
  isIndexPage?: boolean
}

export default function DynamicPage(props: Props) {
  if (!props.page) {
    return null
  }

  let slug: Maybe<string> | undefined = ''

  if (!props.isIndexPage) {
    const pageData = props.page as PageEntity
    slug = pageData.attributes?.slug
  }

  return (
    <Fragment>
      <PageSeo
        data={props.page.attributes?.seo as ComponentSharedSeo}
        slug={slug}
        title={props.page.attributes?.title}
      />
      <RootLayout
        promos={props.promos}
        menus={props.menus}
        notSticky={props.notSticky}
        isWhiteTheme={props.isWhiteTheme}
      >
        {props.page.attributes?.body?.map((body, index) => (
          <BodyRenderer key={nanoid()} body={body} />
        ))}
      </RootLayout>
    </Fragment>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale, params }) => {
  try {
    const menus = await Api.Menus(locale)
    const promos = await Api.PromoBanners(locale)
    const isIndexPage = !params?.slug

    if (isIndexPage) {
      const page = await Api.Homepage(locale)
      return {
        props: { page, menus, promos, isIndexPage },
        revalidate: REVALIDATE_TIME,
      }
    }

    const slug = params.slug?.at(0) as string
    const page = await Api.GetLocalizedPage(locale, slug)

    const isThankYouOrPricingPage =
      page.attributes?.slug === 'thank-you' ||
      page.attributes?.slug === 'pricing'
    if (isThankYouOrPricingPage) {
      return {
        props: { page, menus, promos, notSticky: false, isWhiteTheme: true },
        revalidate: REVALIDATE_TIME,
      }
    }

    return { props: { page, menus, promos }, revalidate: REVALIDATE_TIME }
  } catch (error) {
    return {
      notFound: true,
    }
  }
}
